import React, { useEffect, useRef } from "react";
import { motion, useAnimation, useInView } from "framer-motion";

export default function Steps({ dimension = "150" }) {
  const controls = useAnimation();
  const iconRef = useRef(null);
  const isInView = useInView(iconRef, { once: true });
  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    }
  }, [isInView]);

  const iconPath = {
    hidden: {
      pathLength: 0,
      stroke: "var(--secondary)",
    },
    visible: {
      pathLength: 1,
      stroke: [
        "var(--secondary)",
        "var(--secondary)",
        "var(--secondary)",
        "var(--secondary)",
        "var(--primary)",
      ],
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
      transitionEnd: {
        fill: "var(--primary)",
      },
    },
  };
  return (
    <motion.svg
      width={dimension}
      height={dimension}
      viewBox="0 0 500 500"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={iconRef}
      initial="hidden"
      animate={controls}
    >
      <motion.path
        variants={iconPath}
        strokeWidth="5"
        d="M327.247 72.0001C326.159 71.9959 325.08 72.2064 324.074 72.6194C323.067 73.0325 322.152 73.6399 321.38 74.4071C320.608 75.1743 319.995 76.0861 319.577 77.0904C319.158 78.0946 318.941 79.1716 318.939 80.2598V178.245H172.753C170.558 178.253 168.457 179.134 166.911 180.692C165.365 182.25 164.501 184.358 164.51 186.553V284.538H18.2597C17.9905 284.525 17.7208 284.525 17.4516 284.538C15.4081 284.742 13.5138 285.7 12.1367 287.223C10.7596 288.747 9.99804 290.728 10.0001 292.781V420.588C9.99592 421.677 10.2064 422.755 10.6194 423.761C11.0324 424.768 11.6399 425.684 12.4071 426.455C13.1743 427.227 14.0861 427.84 15.0903 428.259C16.0946 428.678 17.1716 428.894 18.2597 428.897H172.753H327.247H481.692C482.784 428.901 483.866 428.689 484.876 428.273C485.886 427.857 486.803 427.245 487.576 426.472C488.348 425.7 488.96 424.783 489.376 423.773C489.792 422.763 490.004 421.681 490 420.588V80.2598C489.998 79.1716 489.781 78.0946 489.362 77.0903C488.943 76.0861 488.33 75.1743 487.559 74.4071C486.787 73.6399 485.872 73.0324 484.865 72.6194C483.858 72.2064 482.78 71.9959 481.692 72.0001H327.247ZM335.506 88.568H473.448V412.345H335.506V186.553V88.568ZM180.997 194.796H318.939V412.345H180.997V292.781V194.796ZM26.5679 301.106H164.51V412.345H26.5679V301.106Z"
      />
    </motion.svg>
  );
}
