import React from "react";
import Card from "./Card";
import * as styles from "../styles/common.module.css";
import LinearReveal from "./animation/LinearReveal";
import Steps from "./icons/Steps";
import Idea from "./icons/Idea";
import Check from "./icons/Check";
export default function Values() {
  const values = [
    {
      icon: <Steps />,
      title: "Compounding Returns",
      content:
        "We believe all returns in life -- knowledge, wealth, and relationships -- come from compounding, so playing the long game is the secret of value creation. We always look for long-term value creation and mutual benefit in our customer, vendor, and employee relationship.",
    },
    {
      icon: <Idea />,
      title: "First Principle Thinking",
      content:
        "We constantly remind ourselves and challenge each other with first principles thinking. “I don't know what's the matter with people: they don't learn by understanding; they learn by some other way—by rote or something. Their knowledge is so fragile!” — Richard Feynman",
    },
    {
      icon: <Check />,
      title: "First Principle Thinking",
      content:
        "We believe incremental and persistent value addition will yield great products & services. KISSS - Keep It Simple, Straight-forward, and Secure. Wherever possible, we avoid complexity—as simplicity guarantees the greatest levels of user acceptance and adoption.",
    },
  ];
  return (
    <section style={{ marginTop: "75px" }}>
      <LinearReveal>
        <h2
          className={
            "px-5 sub-title text-heading has-text-centered " + styles.sectionHeading
          }
        >
          Our Values
        </h2>
      </LinearReveal>
      <LinearReveal>
        <div className="columns mx-auto px-5" style={{ maxWidth: "1440px" }}>
          {values.map((card, index) => (
            <div key={index} className="column is-flex is-justify-content-center mb-4">
              <Card
                icon={card.icon}
                title={card.title}
                titleClass={"text-heading has-text-centered " + styles.cardTitle}
                cardStyle={{ paddingTop: "30px" }}
                content={card.content}
                contentClass="text-description"
                maxWidth="400px"
              />
            </div>
          ))}{" "}
        </div>{" "}
      </LinearReveal>
    </section>
  );
}
