import React from "react";
import * as styles from "../styles/common.module.css";

export default function CardHover({ bgImage = "", title = "", category = "" }) {
  return (
    <div className={"card " + bgImage + " " + styles.cardHover}>
      <div className="card-content p-0">
        <div
          className={
            "content is-flex is-justify-content-center is-flex-direction-column title py-4 pr-4 " +
            styles.cardHoverText
          }
          style={{
            padding: "16px 25px",
          }}
        >
          <span>{category}</span>{" "}
          <span className={"mt-2 "+ styles.cardHoverTitle} style={{ fontSize: "22px" }}>
            {title}
          </span>
        </div>
      </div>
    </div>
  );
}
