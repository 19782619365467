import React from "react";
import * as styles from "../styles/common.module.css";
import LinearReveal from "./animation/LinearReveal";

export default function Clients() {
  return (
    <section style={{ marginTop: "75px" }}>
      <LinearReveal>
        <h2
          className={
            "px-5 sub-title text-heading has-text-centered " + styles.sectionHeading
          }
        >
          Clients Who Trust Us
        </h2>
      </LinearReveal>
      <LinearReveal>
        <div className="mx-auto mt-5">
          <div
            className="is-flex is-flex-wrap-wrap mx-auto"
            style={{ maxWidth: "600px" }}
          >
            <img
              className={"image mx-auto " + styles.pcimages}
              src="/img/client-partner/albany-international.png"
              alt="Albany International"
            />
            <img
              className={"image mx-auto " + styles.pcimages}
              src="/img/client-partner/state-of-ohio.png"
              alt="State of Ohio"
            />
          </div>
          {/* <div className="is-flex is-justify-space-between m-">
            <img
              className={"image mx-auto " + styles.pcimages}
              src="/img/client-partner/state-of-ohio.png"
              alt="State of Ohio"
            />
          </div> */}
        </div>
      </LinearReveal>
    </section>
  );
}
