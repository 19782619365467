import React from "react";
import * as styles from "../styles/common.module.css";
import LinearReveal from "./animation/LinearReveal";

export default function Partners() {
  return (
    <section style={{ marginTop: "75px" }}>
      <LinearReveal>
        <h2
          className={
            "px-5 sub-title text-heading has-text-centered " + styles.sectionHeading
          }
        >
          We deliver more with a Strong Partner Network
        </h2>
      </LinearReveal>
      <LinearReveal>
        <div className="is-flex is-justify-content-center is-flex-wrap-wrap mx-auto">
          <div className="is-flex is-justify-content-center m-5">
            <div className={styles.pcimages}>
              <img
                className={"image mx-auto"}
                src="/img/client-partner/salesforce-isv-partner.png"
                alt="Salesforce ISV Partner"
              />
            </div>
          </div>
          <div className="is-flex is-justify-content-center m-5">
            <div className={styles.pcimages}>
              <img
                className={"image mx-auto"}
                src="/img/client-partner/salesforce-registered-consulting-partner.png"
                alt="Salesforce Registered Consulting Partner"
              />
            </div>
          </div>
          <div className="is-flex is-justify-content-center m-5">
            <div className={styles.pcimages}>
              <img
                className={"image mx-auto"}
                src="/img/client-partner/snowflake-partner.png"
                alt="Salesforce Snowflake Partner"
              />
            </div>
          </div>
        </div>
      </LinearReveal>
    </section>
  );
}
