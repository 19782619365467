import React from "react";

export default function Card({
  cardStyle = {},
  cardClass = "",
  maxWidth = "300px",
  hasNoShadow = false,
  imageSrc = "",
  altText = "Digital Biz Tech",
  hasPaddedImage = false,
  imageClass = "",
  imageStyle = {},
  icon = null,
  cardContentClass = "",
  title = "Title",
  noTitle = false,
  content = "",
  titleClass = "",
  contentClass = "",
  imageCard = false,
  children,
}) {
  return (
    <div
      className={"card " + cardClass}
      style={{ maxWidth: maxWidth, boxShadow: hasNoShadow ? "none" : "", ...cardStyle }}
    >
      <div className="card-image">
        <figure className={"image m-0 " + (hasPaddedImage ? "px-5 pt-5" : "")}>
          {imageSrc && (
            <img
              src={imageSrc}
              alt={altText}
              className={imageClass}
              style={{ ...imageStyle }}
            />
          )}
          {icon}
        </figure>
      </div>
      {!imageCard && (
        <div className={"card-content " + cardContentClass}>
          {!noTitle && (
            <div className="media mb-0">
              <div className="media-content">
                <h4 className={"title " + titleClass}>{title}</h4>
              </div>
            </div>
          )}
          {content && <div className={"content " + contentClass}>{content}</div>}
        </div>
      )}
      {children && <footer className="card-footer">{children}</footer>}
    </div>
  );
}
