import React from "react";
import * as styles from "../styles/common.module.css";
import { Link } from "gatsby";
import CardHover from "./CardHover";

export default function Work() {
  const caseStudies = [
    {
      title: "Salesforce Integration",
      category: "Case Study -",
      link: "/case-study/salesforce-integration",
      bgImage: styles.salesforceCaseImg,
    },
    {
      title: "CI/CD Integration",
      category: "Case Study -",
      link: "/case-study/CICD-integration",
      bgImage: styles.cicdCaseImg,
    },
  ];
  const products = [
    {
      title: "Timesheet",
      category: "Product -",
      link: "/products/timesheet",
      bgImage: styles.timesheetImg,
    },
    {
      title: "Applicant Tracking System",
      category: "Product -",
      link: "/products/ats",
      bgImage: styles.atsImg,
    },
  ];
  const blogs = [
    {
      title: "Mass Transfer Opportunities in Salesforce",
      category: "Blog -",
      link: "/blog/2023-06-09-mass-transfer-opportunities-in-salesforce",
      bgImage: styles.blog1Img,
    },
    {
      title: "Interacting with External Systems in Salesforce",
      category: "Blog -",
      link: "/blog/2023-05-08-interacting-with-external-systems-in-salesforce-1",
      bgImage: styles.blog2Img,
    },
  ];
  return (
    <section style={{ marginTop: "75px" }}>
      <h2
        className={
          "sub-title text-heading has-text-centered m-5 p-5 " + styles.sectionHeading
        }
      >
        Explore our Work
      </h2>
      <div className="is-flex is-justify-content-space-evenly is-flex-wrap-wrap mx-auto px-1">
        {caseStudies.map((caseStudy, index) => (
          <div className="is-flex is-justify-content-center m-4" key={index}>
            <Link to={caseStudy.link}>
              <CardHover
                title={caseStudy.title}
                category={caseStudy.category}
                bgImage={caseStudy.bgImage}
              />
            </Link>
          </div>
        ))}
      </div>

      <div className="is-flex is-justify-content-space-evenly is-flex-wrap-wrap mx-auto px-1">
        {products.map((product, index) => (
          <div className="is-flex is-justify-content-center m-4" key={index}>
            <Link to={product.link}>
              <CardHover
                title={product.title}
                category={product.category}
                bgImage={product.bgImage}
              />
            </Link>
          </div>
        ))}
      </div>

      <div className="is-flex is-justify-content-space-evenly is-flex-wrap-wrap mx-auto px-1">
        {blogs.map((blog, index) => (
          <div className="is-flex is-justify-content-center m-4" key={index}>
            <Link to={blog.link}>
              <CardHover
                title={blog.title}
                category={blog.category}
                bgImage={blog.bgImage}
              />
            </Link>
          </div>
        ))}
      </div>
    </section>
  );
}
