import React, { useRef, useEffect } from "react";
import * as styles from "../styles/common.module.css";
import { Link } from "gatsby";
import { motion, useAnimation, useInView } from "framer-motion";
import LinearReveal from "./animation/LinearReveal";

export default function Vision() {
  const controls = useAnimation();
  const linksRef = useRef([]);
  const isInView = useInView(linksRef, { once: true });
  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    }
  }, [isInView]);
  const animateDiv = {
    hidden: { opacity: 0, y: 100, x: -100, scale: 0.5 },
    visible: {
      opacity: 1,
      y: 0,
      x: 0,
      scale: 1,
    },
  };
  const visionBg = useRef();
  const visions = [
    {
      imageSrc: "/img/vision/crm-transformation.webp",
      altText: "CRM Transformation",
      title: "Cloud-based Salesforce CRM Transformation",
      to: "/salesforce",
    },
    {
      imageSrc: "/img/vision/data-ai.webp",
      altText: "Data AI",
      title: "Data and A.I.",
      to: "data-engineering",
    },
    {
      imageSrc: "/img/vision/s4-hana.webp",
      altText: "S/4 HANA",
      title: "Digital Transformation with S/4 HANA",
      to: "/sap",
    },
    {
      imageSrc: "/img/vision/talent-aquisition.webp",
      altText: "Talent Acquisition",
      title: "Talent Acquisition Services",
      to: "/talent-acquisition",
    },
  ];
  return (
    <section>
      <div
        className="mt-5 title text-heading has-text-centered mx-auto"
        style={{ padding: "40px 20px", maxWidth: "1000px", letterSpacing: "2px" }}
      >
        <LinearReveal>
          <h1 className={"text-primary " + styles.dbt}>Digital Biz Tech (DBT)</h1>
          <h1 className={"text-heading mt-1 " + styles.dbtInfo}>
            A global technology company with a team of passionate professionals who
            provide value by being part of our client's success story.
          </h1>
        </LinearReveal>
      </div>
      <div
        className={styles.visionContainer}
        style={{ backgroundImage: `url(${visions[0].imageSrc})` }}
        ref={visionBg}
      >
        <div>
          {" "}
          <LinearReveal>
            <h2 className={"has-text-white-bis " + styles.visionHeading}>
              We help build a Digital Future
            </h2>
          </LinearReveal>
          <div className="is-flex">
            {visions.map((vision, i) => (
              <Link
                to={vision.to}
                key={i}
                className={"is-flex is-align-items-flex-end " + styles.visions}
                onMouseOver={() =>
                  (visionBg.current.style.backgroundImage = `url(${visions[i].imageSrc})`)
                }
              >
                <motion.h3
                  ref={i === 0 ? linksRef : null}
                  variants={animateDiv}
                  initial="hidden"
                  animate={controls}
                  className="has-text-white-bis m-4 mb-6"
                  style={{
                    transitionDelay: `${i * 0.1}s`,
                    transitionDuration: "0.5s",
                    height: "75px",
                  }}
                >
                  {vision.title}
                </motion.h3>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
