import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "gatsby";
import { motion } from "framer-motion";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "../assets/css/swipper.css";
import { Autoplay, Pagination, Mousewheel, Keyboard } from "swiper/modules";
export default function Carousel() {
  const carouselSlides = [
    {
      imageSrc: "/img/carousel/hero-salesforce.webp",
      altText: "Digital Biz Tech Salesforce",
      heading: (
        <>
          We work with you as a Trusted Partner to help leverage
          <span className="text-primary has-text-weight-bold"> Salesforce</span> and
          explore unlimited possibilities
        </>
      ),
      ctaLink: "/salesforce",
    },
    {
      imageSrc: "/img/carousel/hero-data-engineering.webp",
      altText: "Digital Biz Tech Data Engineering",
      heading: (
        <>
          Drive real-time Data-Driven insights through efficient
          <span className="text-primary has-text-weight-bold"> Data Engineering</span>
        </>
      ),
      ctaLink: "/data-engineering",
    },
    {
      imageSrc: "/img/carousel/hero-digital-transformation.webp",
      altText: "Digital Biz Tech Digital Transformation",
      heading: (
        <>
          <span className="text-primary has-text-weight-bold"> SAP S/4 HANA</span> -
          Accelerating Digital Transformation
        </>
      ),
      ctaLink: "/sap",
    },
    {
      imageSrc: "/img/carousel/hero-great-talent.webp",
      altText: "Digital Biz Tech Hiring",
      heading: (
        <>
          Helping Organizations{" "}
          <span className="text-primary has-text-weight-bold">
            Match Great Talent to Great Opportunity
          </span>
        </>
      ),
      ctaLink: "/talent-acquisition",
    },
  ];
  return (
    <div>
      <Swiper
        style={{
          "--swiper-pagination-color": "#ed8139",
          "--swiper-pagination-bullet-size": "10px",
          "--swiper-pagination-bullet-inactive-color": "#fafafa",
          "--swiper-pagination-bullet-inactive-opacity": "0.4",
        }}
        cssMode={true}
        pagination={{
          clickable: true,
        }}
        mousewheel={true}
        grabCursor={true}
        loop={true}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
        }}
        keyboard={{
          enabled: true,
        }}
        modules={[Autoplay, Pagination, Mousewheel, Keyboard]}
        className="mySwiper"
      >
        {carouselSlides.map((slide, index) => (
          <SwiperSlide key={index}>
            <div className="imageContainer">
              <img src={slide.imageSrc} alt={slide.altText} className="img" />
              <div className="overlay">
                <motion.h1 className="has-text-light">{slide.heading}</motion.h1>
                <Link to={slide.ctaLink}>
                  <motion.button
                    className="button button-primary button-primary-hover carousel-btn"
                    style={{ transitionDuration: "0s" }}
                    whileHover={{
                      scale: 1.05,
                    }}
                  >
                    Learn More
                  </motion.button>
                </Link>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <span id="section-ends"></span>
    </div>
  );
}
