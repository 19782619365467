import React from "react";
import { Helmet } from "react-helmet";
export default function Meta({
  title = "Digital Biz Tech",
  description = "Salesforce -- Simplified! Helping Transform Business Growth with Salesforce Certified Consultants. Explore our value-add to the Salesforce Community. We are also experts in the field of Web Development. Want your business or company's own website. Contact us now.",
  url = "https://digitalbiz.tech",
}) {
  if (typeof window !== "undefined") {
    if (window.location.href) {
      url = window.location.href;
    }
  }
  return (
    <Helmet>
      {/* Primary Meta Tags  */}
      <title>{title}</title>
      <meta name="description" content={description} />
      {/*  Open Graph / Facebook  */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content="https://www.digitalbiz.tech/img/dbt-logo.svg" />
      Twitter
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={url} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta
        property="twitter:image"
        content="https://www.digitalbiz.tech/img/dbt-logo.svg"
      />
      {/* Keyboards */}
      <meta
        name="keywords"
        content="
          Salesforce,
          Business Development,
          Salesforce Governance,
          Data Management,
          Salesforce Administartion,
          CRM platform service,
          Data Manager,
          Automation,
          Salesforce Blogs,
          Salesforce Process,
          Lightning Components,
          Salesforce Internship,
          Salesforce Careers,
          Salesforce Learning,
          Salesforce content,
          Salesforce Training,
          Business Processes,
          Simplified Collaboration,
          Digital biz tech,
          DBT,
          Salesforce internship,
          Integrate Services,
          Managing Customer Interactions,
          Managing Opportunities,
          Managing Sales Process,
          Data Engineering,
          Talent Acquisition,
          development agency in Ohio,
          development company in USA,
          development company in US,
          SAP consultant,
          sap consultants,
          sap consultants in us,
          sap in ohio,
          experts in sap,
          sap experts,
          sap in usa"
      />
    </Helmet>
  );
}
