import React, { useEffect, useRef } from "react";
import { motion, useAnimation, useInView } from "framer-motion";

export default function LinearReveal({ children }) {
  const controls = useAnimation();
  const divRef = useRef(null);
  const isInView = useInView(divRef, { once: true });
  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    }
  }, [isInView]);
  const animateDiv = {
    hidden: { opacity: 0, y: 100 },
    visible: { opacity: 1,  y: 0, transition: { duration: 0.5 } },
  };
  return (
    <motion.div ref={divRef} variants={animateDiv} initial="hidden" animate={controls}>
      {children}
    </motion.div>
  );
}
