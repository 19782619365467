import React, { useEffect, useRef } from "react";
import { motion, useAnimation, useInView } from "framer-motion";

export default function Idea({ dimension = "150" }) {
  const controls = useAnimation();
  const iconRef = useRef(null);
  const isInView = useInView(iconRef, { once: true });
  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    }
  }, [isInView]);

  const iconPath = {
    hidden: {
      pathLength: 0,
      stroke: "var(--secondary)",
    },
    visible: {
      pathLength: 1,
      stroke: [
        "var(--secondary)",
        "var(--secondary)",
        "var(--secondary)",
        "var(--secondary)",
        "var(--primary)",
      ],
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
      transitionEnd: {
        fill: "var(--primary)",
      },
    },
  };
  return (
    <motion.svg
      width={dimension}
      height={dimension}
      viewBox="0 0 500 500"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={iconRef}
      initial="hidden"
      animate={controls}
    >
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M294.832 459.47H94.4848C83.7015 459.47 75.7387 448.83 79.8058 439.045C98.6765 393.798 143.009 367.878 194.651 367.878C246.308 367.878 290.641 393.798 309.511 439.045C313.578 448.83 305.616 459.47 294.832 459.47ZM132.32 276.286C132.32 242.611 160.291 215.225 194.651 215.225C229.026 215.225 256.982 242.611 256.982 276.286C256.982 309.961 229.026 337.347 194.651 337.347C160.291 337.347 132.32 309.961 132.32 276.286ZM346.629 453.913C335.301 402.637 299.866 364.794 253.226 347.62C277.941 328.127 292.355 296.603 287.057 262.088C280.917 222.048 246.915 190.006 205.87 185.335C149.212 178.878 101.154 222.079 101.154 276.286C101.154 305.137 114.805 330.844 136.091 347.62C89.4359 364.794 54.0163 402.637 42.672 453.913C38.5582 472.552 53.8916 490 73.3545 490H315.947C335.425 490 350.759 472.552 346.629 453.913Z"
        variants={iconPath}
        strokeWidth="5"
      />
      <motion.path
        d="M326.898 67.5004C309.702 68.2263 293.406 75.3827 281.236 87.5527C269.066 99.7228 261.909 116.019 261.184 133.215C261.318 150.637 268.038 167.364 279.994 180.036C284.108 183.315 287.484 187.423 289.905 192.094C292.325 196.764 293.734 201.892 294.041 207.143C294.041 209.322 294.906 211.411 296.447 212.952C297.987 214.492 300.076 215.358 302.255 215.358H351.541C353.719 215.358 355.809 214.492 357.349 212.952C358.89 211.411 359.755 209.322 359.755 207.143C360.013 201.922 361.385 196.816 363.779 192.169C366.174 187.522 369.535 183.441 373.637 180.2C379.801 174.05 384.66 166.718 387.92 158.644C391.181 150.57 392.777 141.921 392.612 133.215C392.619 124.301 390.85 115.476 387.409 107.254C383.968 99.0312 378.923 91.5768 372.569 85.3254C366.534 79.453 359.383 74.8499 351.539 71.7883C343.694 68.7267 335.315 67.2687 326.898 67.5004ZM362.548 168.372C353.242 176.288 346.756 187.011 344.066 198.929H309.73C307.064 187.122 300.74 176.457 291.659 168.454C282.761 158.861 277.753 146.298 277.612 133.215C278.194 120.33 283.573 108.129 292.693 99.0096C301.813 89.8898 314.014 84.5105 326.898 83.929C333.147 83.7123 339.376 84.7652 345.207 87.0242C351.038 89.2832 356.35 92.7013 360.823 97.0719C365.663 101.769 369.515 107.385 372.153 113.592C374.791 119.799 376.161 126.471 376.184 133.215C376.329 139.704 375.176 146.157 372.792 152.195C370.408 158.232 366.841 163.732 362.301 168.372H362.548Z"
        variants={iconPath}
        strokeWidth="5"
      />
      <motion.path
        d="M351.541 223.571H302.255C300.077 223.571 297.987 224.437 296.447 225.977C294.906 227.518 294.041 229.607 294.041 231.786C294.041 233.964 294.906 236.053 296.447 237.594C297.987 239.134 300.077 240 302.255 240H351.541C353.72 240 355.809 239.134 357.349 237.594C358.89 236.053 359.755 233.964 359.755 231.786C359.755 229.607 358.89 227.518 357.349 225.977C355.809 224.437 353.72 223.571 351.541 223.571Z"
        variants={iconPath}
        strokeWidth="5"
      />
      <motion.path
        d="M343.327 248.214H310.47C308.292 248.214 306.202 249.079 304.662 250.62C303.121 252.16 302.256 254.25 302.256 256.428C302.256 258.607 303.121 260.696 304.662 262.237C306.202 263.777 308.292 264.642 310.47 264.642H343.327C345.506 264.642 347.595 263.777 349.136 262.237C350.676 260.696 351.542 258.607 351.542 256.428C351.542 254.25 350.676 252.16 349.136 250.62C347.595 249.079 345.506 248.214 343.327 248.214Z"
        variants={iconPath}
        strokeWidth="5"
      />
      <motion.path
        d="M326.898 51.071C329.076 51.071 331.166 50.2056 332.706 48.6651C334.247 47.1246 335.112 45.0353 335.112 42.8567V26.4282C335.112 24.2496 334.247 22.1603 332.706 20.6198C331.166 19.0793 329.076 18.2139 326.898 18.2139C324.719 18.2139 322.63 19.0793 321.09 20.6198C319.549 22.1603 318.684 24.2496 318.684 26.4282V42.8567C318.684 45.0353 319.549 47.1246 321.09 48.6651C322.63 50.2056 324.719 51.071 326.898 51.071Z"
        variants={iconPath}
        strokeWidth="5"
      />
      <motion.path
        d="M236.541 125H220.113C217.934 125 215.845 125.865 214.304 127.406C212.764 128.946 211.898 131.036 211.898 133.214C211.898 135.393 212.764 137.482 214.304 139.023C215.845 140.563 217.934 141.429 220.113 141.429H236.541C238.72 141.429 240.809 140.563 242.35 139.023C243.89 137.482 244.756 135.393 244.756 133.214C244.756 131.036 243.89 128.946 242.35 127.406C240.809 125.865 238.72 125 236.541 125Z"
        variants={iconPath}
        strokeWidth="5"
      />
      <motion.path
        d="M433.684 125H417.255C415.077 125 412.987 125.865 411.447 127.406C409.906 128.946 409.041 131.036 409.041 133.214C409.041 135.393 409.906 137.482 411.447 139.023C412.987 140.563 415.077 141.429 417.255 141.429H433.684C435.862 141.429 437.952 140.563 439.492 139.023C441.033 137.482 441.898 135.393 441.898 133.214C441.898 131.036 441.033 128.946 439.492 127.406C437.952 125.865 435.862 125 433.684 125Z"
        variants={iconPath}
        strokeWidth="5"
      />
      <motion.path
        d="M408.22 51.8926C406.681 50.3626 404.599 49.5039 402.429 49.5039C400.259 49.5039 398.177 50.3626 396.638 51.8926L384.974 63.4747C384.204 64.2383 383.593 65.1468 383.176 66.1478C382.759 67.1488 382.544 68.2225 382.544 69.3068C382.544 70.3912 382.759 71.4649 383.176 72.4659C383.593 73.4669 384.204 74.3754 384.974 75.139C385.741 75.9003 386.652 76.5026 387.652 76.9114C388.653 77.3202 389.725 77.5274 390.806 77.5211C391.887 77.5274 392.959 77.3202 393.959 76.9114C394.96 76.5026 395.87 75.9003 396.638 75.139L408.22 63.4747C409.75 61.9357 410.609 59.8537 410.609 57.6836C410.609 55.5135 409.75 53.4316 408.22 51.8926Z"
        variants={iconPath}
        strokeWidth="5"
      />
      <motion.path
        d="M268.823 75.1396C269.593 74.376 270.204 73.4674 270.621 72.4665C271.038 71.4655 271.253 70.3918 271.253 69.3074C271.253 68.2231 271.038 67.1494 270.621 66.1484C270.204 65.1474 269.593 64.2389 268.823 63.4753L257.159 51.8932C255.587 50.5474 253.566 49.8442 251.499 49.9241C249.431 50.0039 247.47 50.861 246.007 52.3239C244.545 53.7868 243.688 55.7479 243.608 57.8152C243.528 59.8826 244.231 61.9039 245.577 63.4753L257.159 75.1396C257.926 75.9009 258.837 76.5032 259.838 76.912C260.838 77.3208 261.91 77.528 262.991 77.5217C264.072 77.528 265.144 77.3208 266.145 76.912C267.145 76.5032 268.056 75.9009 268.823 75.1396Z"
        variants={iconPath}
        strokeWidth="5"
      />
    </motion.svg>
  );
}
