import React, { useEffect, useRef } from "react";
import { motion, useAnimation, useInView } from "framer-motion";

export default function Check({ dimension = "150" }) {
  const controls = useAnimation();
  const iconRef = useRef(null);
  const isInView = useInView(iconRef, { once: true });
  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    }
  }, [isInView]);

  const iconPath = {
    hidden: {
      pathLength: 0,
      stroke: "var(--secondary)",
    },
    visible: {
      pathLength: 1,
      stroke: [
        "var(--secondary)",
        "var(--secondary)",
        "var(--secondary)",
        "var(--secondary)",
        "var(--primary)",
      ],
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
      transitionEnd: {
        fill: "var(--primary)",
      },
    },
  };
  return (
    <motion.svg
      width={dimension}
      height={dimension}
      viewBox="0 0 500 500"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={iconRef}
      initial="hidden"
      animate={controls}
    >
      <motion.path
        variants={iconPath}
        strokeWidth="5"
        d="M250 10C117.665 10 10 117.665 10 250C10 382.335 117.665 490 250 490C382.335 490 490 382.335 490 250C490 117.665 382.335 10 250 10ZM250 474.516C126.181 474.516 25.4839 373.819 25.4839 250C25.4839 126.181 126.181 25.4839 250 25.4839C373.819 25.4839 474.516 126.181 474.516 250C474.516 373.819 373.819 474.516 250 474.516ZM381.974 162.103C385.019 165.148 385.019 170.052 381.974 173.045L220.065 334.955C218.619 336.4 216.658 337.226 214.594 337.226C212.529 337.226 210.568 336.4 209.123 334.955L123.91 249.69C120.865 246.645 120.865 241.742 123.91 238.748C126.955 235.703 131.858 235.703 134.852 238.748L214.645 318.542L371.032 162.155C374.026 159.11 378.929 159.11 381.974 162.103Z"
      />
    </motion.svg>
  );
}
