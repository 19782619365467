import React from "react";
import Meta from "../components/Meta";
import Layout from "../components/Layout";
import Carousel from "../components/Carousel";
import Vision from "../components/Vision";
import Partners from "../components/Partners";
import Clients from "../components/Clients";
import Work from "../components/Work";
import Values from "../components/Values";

export default function Index() {
  return (
    <Layout>
      <Meta />
      <Carousel />
      <Vision />
      <Partners />
      <Clients />
      <Work />
      <Values />
    </Layout>
  );
}
